import { cookieConfig } from "@configs/cookieConfig";
import { setAuthCookie } from "@utils/auth-utils";
import clientAPI from "@utils/axios/client-api";
import cookies from "js-cookie";
import qs from "querystringify";

export const setCookieValue = ({ key, value }) => {
  cookies.set(key, value, cookieConfig);
};

/**
 * @api {post} /api/v1/user/session/general/${type}
 * @apiDescription login
 */

const loginPoint = (postData) => {
  const { type } = postData;
  return clientAPI
    .post(`/api/v1/user/session/general/${type}`, postData)
    .then((res) => {
      const { data } = res.data;
      if (data) {
        setAuthCookie(data);
        cookies.set("loginType", type, cookieConfig);
        console.log(`${type} user Login`);
      }
      return res.data;
    });
};

/**
 * @api {get} /api/v1/user/role/${role}
 * @apiDescription getSingleConsoleRole
 */

const getSingleConsoleRole = (postData) => {
  const { role, pageNum } = postData;

  const query = qs.stringify({
    pageNum,
  });
  return clientAPI
    .get(`/api/v1/user/role/${role}?${query}`)
    .then(({ data }) => data);
};

/**
 * @api {get} /api/v1/user/role
 * @apiDescription getAllConsoleRoles
 */

const getAllConsoleRoles = () =>
  clientAPI.get("/api/v1/user/role").then(({ data }) => data);

/**
 * @api {get} /api/v1/user/privilege
 * @apiDescription getUserPrivilege
 */

const getUserPrivilege = () =>
  clientAPI.get("/api/v1/user/privilege").then((response) => {
    const { data } = response.data;
    return {
      data: data.map((item) => item.key),
    };
  });

/**
 * @api {get} /api/v1/user/privacy
 * @apiDescription getUserPrivacy
 */
const getUserPrivacy = async () =>
  clientAPI.get("/api/v1/user/privacy").then((res) => res.data);

/**
 * @api {get} /api/v1/user/sign_check/${type}?value=${encodeURIComponent(value)}
 * @apiDescription signCheck
 */

const signCheck = (postData) => {
  const { type, value } = postData;
  const query = qs.stringify({
    value,
  });
  return clientAPI
    .get(`/api/v1/user/sign_check/${type}?${query}`)
    .then((res) => res.data);
};

/**
 * @api {post} /api/v1/user/role
 * @apiDescription postUserRole
 */

const postUserRole = (postData) =>
  clientAPI.post("/api/v1/user/role", postData).then(({ data }) => data);

/**
 * @api {get} /api/v1/search/tag?keyword=${keyword}&type=${type}
 * @apiDescription searchTag
 */

const searchTagPoint = (postData) => {
  const { keyword, type } = postData;
  return clientAPI
    .get(`/api/v1/search/tag?keyword=${keyword}&type=${type}`)
    .then(({ data }) => data);
};

/**
 * @api {put} /api/v1/user/password
 * @apiDescription changePassword
 */

const changePasswordPoint = (postData) =>
  clientAPI.put("/api/v1/user/password", postData).then(({ data }) => data);

/**
 * @api {post} /api/v1/user/forget_pwd/temp_code
 * @apiDescription sendForgetPasswordTempCode
 */

const sendForgetPasswordTempCode = (postData) => {
  const query = qs.stringify({
    email: postData.email,
  });
  return clientAPI
    .post(`/api/v1/user/forget_pwd/temp_code?${query}`, postData)
    .then(({ data }) => data);
};

/**
 * @api {get} `/api/v1/search/user?keyword=${keyword}&pageNum=0&pageSize=7&sortKey=email&sortOrder=1`,
 * @apiDescription searchUserTag
 */

const searchUserTag = (keyword, pageNum = 0) =>
  clientAPI
    .get(
      `/api/v1/search/user?keyword=${keyword}&pageNum=${pageNum}&pageSize=7&sortKey=email&sortOrder=1`,
    )
    .then(({ data }) => data);

/**
 * @api {post} /api/v1/user
 * @apiDescription sign up
 */

const signUpPoint = (postData) =>
  clientAPI.post("/api/v1/user", postData).then((res) => {
    const json = res.data;
    cookies.set("loginType", "email", cookieConfig);
    setAuthCookie(json.data);
    return json;
  });

/**
 * @api {delete} /api/v1/user/role?role=${role}&userId=${userId}
 * @apiDescription delete role
 */

const deleteRole = (postData) => {
  const { role, userId } = postData;
  const query = qs.stringify({
    role,
    userId,
  });
  return clientAPI
    .delete(`/api/v1/user/role?${query}`)
    .then(({ data }) => data);
};

/**
 * @api {put} /api/v1/user/privacy
 * @apiDescription privacy update
 */

const privacyUpdate = (postData) =>
  clientAPI.put("/api/v1/user/privacy", postData).then(({ data }) => data);

/**
 * @api {put} /api/v1/user/reset_pwd
 * @apiDescription reset password
 */

const resetPasswordPoint = (postData) =>
  clientAPI.put("/api/v1/user/reset_pwd", postData).then(({ data }) => data);

/**
 * @api {post} /api/v1/user/forget_pwd/verification
 * @apiDescription temp code verification
 */

const tempCodeVerification = (postData) =>
  clientAPI
    .post("/api/v1/user/forget_pwd/verification", postData)
    .then(({ data }) => data);

export const postAvatar = (data) =>
  clientAPI
    .post("/api/v1/user/profile/avatar", data)
    .then((response) => response.data);

/**
 * @api {delete} /api/v1/user/profile/avatar
 * @apiDescription delete avatar
 */

const deleteAvatar = (postData) =>
  clientAPI
    .delete("/api/v1/user/profile/avatar", postData)
    .then(({ data }) => data);

/**
 * @api {post} /api/v1/user/session/social/{type}
 * @param type "google" || "facebook"
 * @apiDescription google social login
 */
const postSessionSocial = ({ type = "google", data }) =>
  clientAPI
    .post(`/api/v1/user/session/social/${type}`, data)
    .then((res) => res.data);

const googleLogin = async (postData) => {
  try {
    const { email, idToken } = postData;
    const { data, error } = await postSessionSocial({
      type: "google",
      data: {
        email,
        token: idToken,
      },
    });
    if (error) {
      throw error;
    }
    setAuthCookie(data);
    cookies.set("loginType", "google", cookieConfig);
    return { data };
  } catch (error) {
    console.error("googleLogin", error);
    return { error };
  }
};

const facebookLogin = async (postData) => {
  try {
    const { email, token } = postData;
    const { data, error } = await postSessionSocial({
      type: "facebook",
      data: {
        email,
        token,
      },
    });
    if (error) {
      throw error;
    }
    setAuthCookie(data);
    cookies.set("loginType", "facebook", cookieConfig);
    return { data };
  } catch (error) {
    console.error("facebookLogin", error);
    return { error };
  }
};

/**
 * @api {put}
 * @apiDescription post billing info
 */

const postBilling = (postData) =>
  clientAPI.put("/api/v1/user/billing", postData).then(({ data }) => data);

/**
 * @api {put}
 * @apiDescription post profile info
 */

const putProfile = (postData) => {
  return clientAPI
    .put("/api/v1/user/profile", postData)
    .then(({ data }) => data);
};

/**
 * @api {post} /api/v1/user/verify_code
 * @apiDescription send verify
 */

const sendVerify = (postData) =>
  clientAPI.post("/api/v1/user/verify_code", postData).then(({ data }) => data);

/**
 * @api {post} /api/v1/user/session/company/${type}
 * @apiDescription Ad Login Point
 */

const adLoginPoint = (postData) => {
  const { type } = postData;
  return clientAPI
    .post(`/api/v1/user/session/company/${type}`, postData)
    .then((res) => {
      const { data: adRes } = res;
      setAuthCookie(adRes.data);
      return adRes;
    });
};

const getBillingInfo = () =>
  clientAPI.get("/api/v1/user/billing").then(({ data }) => data);

const putBillingInfo = ({ billingData }) =>
  clientAPI.put("/api/v1/user/billing", billingData).then(({ data }) => data);

const getUserProfile = () =>
  clientAPI.get("/api/v1/user/profile").then(({ data }) => data);

/**
 * @api {post} /api/v1/user/sign_up/temp_code
 * @apiDescription send sign_up temp code
 */

const sendSignupTempCode = (postData) => {
  const query = qs.stringify({
    email: postData.email,
  });
  return clientAPI
    .post(`/api/v1/user/sign_up/temp_code?${query}`, postData)
    .then(({ data }) => data);
};

/**
 * @api {post} /api/v1/user/sign_up/verification
 * @apiDescription sign up temp code verification
 */

const signupTempCodeVerification = (postData) =>
  clientAPI
    .post("/api/v1/user/sign_up/verification", postData)
    .then(({ data }) => data);

/**
 * @api {get} /api/v1/user/profile/unfinished_field
 */
export const getUserUnfinishedField = () => {
  return clientAPI
    .get("/api/v1/user/profile/unfinished_field")
    .then((response) => response.data);
};

/**
 *@api {get}/api/v1/user/profile/user_config
 */
const getUserConfig = () => {
  const query = qs.stringify({
    pageNum: "",
    pageSize: 100,
    sortKey: "serial_number",
    sortOrder: -1,
  });
  return clientAPI
    .get(`/api/v1/user/profile/user_config?${query}`)
    .then((response) => response.data);
};

const updateTrackingCode = ({ postBody }) => {
  return clientAPI
    .put(`/api/v1/user/console/tracking_code`, postBody)
    .then((res) => res.data);
};

/**
 *@api {get} /api/v1/user/console/member_profile
 */
const getMemberManagementList = ({
  keyword,
  sortKey,
  sortOrder,
  pageNum,
  pageSize,
}) => {
  const query = qs.stringify({
    keyword,
    pageNum,
    pageSize,
    sortKey,
    sortOrder,
  });
  return clientAPI
    .get(`/api/v1/user/console/member_profile?${query}`)
    .then((response) => response.data);
};

/**
 *@api {put} api/v1/user/console/1/tracking_code
 */
const putMemberTrackingCode = ({ id, trackingCode }) => {
  console.log({ id, trackingCode });
  return clientAPI
    .put(
      `/api/v1/user/console/${id}/tracking_code?trackingCode=${trackingCode}`,
    )
    .then((res) => res.data);
};

/**
 *@api {put} api/v1/user/console/1/tracking_code
 */
const renewUserVerifyCode = ({ userId }) => {
  return clientAPI
    .post(`/api/v1/user/console/user/${userId}/sign_up/verify_code`)
    .then((res) => res.data);
};

export {
  adLoginPoint,
  changePasswordPoint,
  cookieConfig,
  deleteAvatar,
  deleteRole,
  facebookLogin,
  getAllConsoleRoles,
  getBillingInfo,
  getMemberManagementList,
  getSingleConsoleRole,
  getUserConfig,
  getUserPrivacy,
  getUserPrivilege,
  getUserProfile,
  googleLogin,
  loginPoint,
  postBilling,
  postUserRole,
  privacyUpdate,
  putBillingInfo,
  putMemberTrackingCode,
  putProfile,
  renewUserVerifyCode,
  resetPasswordPoint,
  searchTagPoint,
  searchUserTag,
  sendForgetPasswordTempCode,
  sendSignupTempCode,
  sendVerify,
  signCheck,
  signUpPoint,
  signupTempCodeVerification,
  tempCodeVerification,
  updateTrackingCode,
};
