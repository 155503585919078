import { useAppContext } from "@components/context";
import { loggingLoggedIn } from "@utils/apiServices/logging-services";
import { getUserPrivacy, getUserPrivilege } from "@utils/apiServices/user";
import { isTokenAvailable } from "@utils/auth-utils";
import { useProgressBar } from "@utils/hooks/use-progress-bar";
import { loginDirectPath } from "@utils/page-utils";
import cookies from "js-cookie";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { selectDefaultType } from "./utils";

export const useLoginFlowData = () => {
  const {
    loginStatus,
    setLoginStatus,
    setUserPrivacy,
    setUserPrivilege,
    loginCallback,
    setLoginCallback,
    showServiceUnavailable,
  } = useAppContext();
  const { setShowProgressBar } = useProgressBar();
  const router = useRouter();

  const { loginType: userLoginType } = loginStatus || {};
  const { pathname } = router;

  const [display, setDisplay] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showForget, setShowForget] = useState(false);
  const [forgetView, setForgetView] = useState("default");
  const [signupView, setSignupView] = useState("default");
  const defaultType = selectDefaultType(userLoginType);
  const [loginView, setLoginView] = useState(defaultType);

  const [showInvitation, setShowInvitation] = useState(false);

  const logInSuccessCallback = async () => {
    try {
      const { data: privilegeData } = await getUserPrivilege();
      const { data: privacyData } = await getUserPrivacy();
      if (privilegeData) {
        setUserPrivilege(privilegeData);
      }
      if (privacyData) {
        setUserPrivacy(privacyData);
        loggingLoggedIn();
      }
    } catch (error) {
      console.error("[Login] get user info failed", error);
    }
  };

  const loginSuccessProcess = async ({
    resultData,
    type,
    closePopup = true,
  }) => {
    setLoginStatus({
      ...resultData,
      loginType: type,
      isTokenAvailable: isTokenAvailable(resultData.token),
    });
    if (closePopup) {
      setShowLogin(false);
      setShowSignup(false);
      setShowForget(false);
    }
    await logInSuccessCallback();
    loginCallback();
    /**
     * set functions in state using React Hooks
     * https://github.com/facebook/react/issues/14087
     */
    setLoginCallback([
      () => {
        // empty initial value
      },
    ]);
  };

  useEffect(() => {
    if (
      window.location.hash === "#tokenInvalid" ||
      window.location.hash === "#401"
    ) {
      setLoginStatus((prev) => ({ ...prev, token: "" }));
      setUserPrivilege([]);
      setShowLogin(true);
      const clearHash = () => {
        router.replace(window.location.pathname + window.location.search);
        setShowProgressBar(false);
      };
      setLoginCallback([clearHash]);
      cookies.remove("token");
    }
    if (pathname === "/login/invite") {
      setShowInvitation(true);
    }
    if (pathname === "/login/forgetPassword") {
      setShowForget(true);
    }
  }, [router, setLoginStatus, setShowLogin, setUserPrivilege]);

  useEffect(() => {
    const routeChangeStart = () => {
      setShowLogin(false);
      setShowSignup(false);
      setShowForget(false);
    };
    router.events.on("routeChangeStart", routeChangeStart);
    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
    };
  }, [router]);

  useEffect(() => {
    if (showLogin) {
      setLoginView(defaultType);
    }
  }, [showLogin]);

  useEffect(() => {
    if (showServiceUnavailable) {
      setShowLogin(false);
      setShowSignup(false);
    }
  }, [showServiceUnavailable, showLogin, showSignup]);

  useEffect(() => {
    if (router.query.redirect_uri && !loginStatus.token && !showSignup) {
      setShowLogin(true);
      setLoginCallback([
        () => router.push(loginDirectPath({ router, localStorage })),
      ]);
    }
  }, [router, loginStatus, setShowLogin, setLoginCallback, showSignup]);

  return {
    display,
    showForget,
    forgetView,
    signupView,
    loginView,
    defaultType,
    showLogin,
    setShowLogin,
    showSignup,
    setShowSignup,
    setDisplay,
    setShowForget,
    setForgetView,
    setSignupView,
    setLoginView,
    showInvitation,
    setShowInvitation,
    loginSuccessProcess,
  };
};
