import { Provider, useAppContext } from "@components/context";
import dynamic from "next/dynamic";
import { forwardRef, useImperativeHandle } from "react";

import { useLoginFlowData } from "./hooks.tsx";

const LoginPopup = dynamic(
  () => import("@page-components/Header/LoginFlow/LoginPopup"),
  {
    ssr: false,
  },
);

const ForgetPasswordPopup = dynamic(
  () => import("@page-components/Header/LoginFlow/ForgetPasswordPopup"),
  {
    ssr: false,
  },
);

const InvitationPopup = dynamic(
  () => import("@page-components/Header/LoginFlow/InvitationPopup"),
  {
    ssr: false,
  },
);

const SignupPopup = dynamic(
  () => import("@page-components/Header/LoginFlow/SignupPopup"),
  {
    ssr: false,
  },
);

const LoginFlow = ({ forwardedRef }) => {
  const baseContext = useAppContext();
  const {
    loginView,
    setLoginView,
    showForget,
    setShowForget,
    forgetView,
    setForgetView,
    signupView,
    setSignupView,
    showLogin,
    setShowLogin,
    showSignup,
    setShowSignup,
    showInvitation,
    setShowInvitation,
    loginSuccessProcess,
  } = useLoginFlowData();

  useImperativeHandle(forwardedRef, () => ({
    open: () => setShowLogin(true),
  }));

  const loginFlowContext = {
    ...baseContext,
    loginView,
    setLoginView,
    showForget,
    setShowForget,
    forgetView,
    setForgetView,
    signupView,
    setSignupView,
    showLogin,
    setShowLogin,
    showSignup,
    setShowSignup,
    showInvitation,
    setShowInvitation,
    loginSuccessProcess,
  };

  return (
    <Provider value={loginFlowContext}>
      {showLogin ? <LoginPopup /> : null}
      {showSignup ? <SignupPopup /> : null}
      {showForget ? <ForgetPasswordPopup /> : null}
      {showInvitation ? <InvitationPopup /> : null}
    </Provider>
  );
};

const ForwardedLoginFlow = forwardRef((props, ref) => (
  <LoginFlow {...props} forwardedRef={ref} />
));

ForwardedLoginFlow.displayName = "LoginFlow";

export default ForwardedLoginFlow;
