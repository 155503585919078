export const selectDefaultType = (loginType) => {
  switch (loginType) {
    case "email":
      return "lastEmail";
    case "facebook":
    case "google":
      return "continue";
    default:
      return "default";
  }
};
