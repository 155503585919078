import { displayUnauthorized } from "@components/AppEnv";
import { Provider, useAppContext } from "@components/context";
import Backdrop from "@material-ui/core/Backdrop";
import Notification from "@page-components/Header/Notification";
import { isTokenAvailable } from "@utils/auth-utils";
import { useProgressBar } from "@utils/hooks/use-progress-bar";
import clsx from "clsx";
import dynamic from "next/dynamic";
import React from "react";
import { useIntl } from "react-intl";

import * as S from "./Header.module.scss";
import { useHeaderData, useProfileData } from "./hooks.tsx";
import LoginButton from "./LoginButton.tsx";
import LoginFlow from "./LoginFlow";
import PermittedTab from "./PermittedTab.tsx";

const CategoryMenu = dynamic(() => import("./CategoryMenu"), {
  ssr: false,
});
const IntlButton = dynamic(() => import("@components/IntlButton"), {
  ssr: false,
});
const HeaderLogo = dynamic(() => import("./HeaderLogo"), {
  ssr: false,
});
const CookieNotice = dynamic(
  () => import("@page-components/Header/CookieNotice"),
  {
    ssr: false,
  },
);
const ProfileMenu = dynamic(
  () => import("@page-components/Header/ProfileMenu"),
  {
    ssr: false,
  },
);

const Header = ({
  data = {},
  onSetDisplayHeader = () => null,
  autoHide = false,
}) => {
  const intl = useIntl();
  const baseContext = useAppContext();
  const { LinearProgress } = useProgressBar();
  const { theme } = data;
  const { loginStatus, userPrivilege = [], loginRef } = baseContext;
  const { token = "" } = loginStatus || {};
  const {
    display,
    showCategoryMenu,
    setShowCategoryMenu,
    showProfileMenu,
    setShowProfileMenu,
    handleClick,
  } = useHeaderData({ onSetDisplayHeader });

  useProfileData({ token });

  const hasToken = isTokenAvailable(token) === true;
  const hasTokenAndDisplayUnauthorized = hasToken || displayUnauthorized;

  const headerContext = {
    ...baseContext,
  };

  return (
    <Provider value={headerContext}>
      <LoginFlow ref={loginRef} />
      <Backdrop
        open={showCategoryMenu}
        classes={{
          root: S.backdrop,
        }}
        onClick={() => setShowCategoryMenu(false)}
      />
      <header
        id="header"
        className={clsx({
          [S.show]: display,
          [S.header]: true,
          [S.autoHide]: autoHide,
        })}>
        <CategoryMenu
          show={showCategoryMenu}
          onMouseLeave={() => setShowCategoryMenu(false)}
          onClickWrapper={() => setShowCategoryMenu(false)}
        />

        <div className={S.headerInner}>
          <a
            href={`${hasTokenAndDisplayUnauthorized ? "/" : "#"}`}
            onClick={(event) =>
              hasTokenAndDisplayUnauthorized
                ? handleClick(event, "")
                : event.preventDefault()
            }
            style={{
              cursor: hasTokenAndDisplayUnauthorized ? "pointer" : "default",
            }}>
            <HeaderLogo />
          </a>
          <div className={S.menu}>
            <div className={S.menuList}>
              {hasTokenAndDisplayUnauthorized ? (
                <a
                  test-id="explore"
                  href={"/"}
                  onClick={(event) => handleClick(event, "")}
                  onMouseEnter={() => {
                    setShowCategoryMenu(true);
                  }}>
                  <div
                    className={clsx({
                      [S.menuListInner]: true,
                      [S.active]: theme === "explore",
                    })}>
                    <div>
                      {intl.formatMessage({
                        id: "common.header.explore",
                        defaultMessage: "Explore",
                      })}
                    </div>
                  </div>
                </a>
              ) : null}
              {hasToken ? (
                <a
                  href={"/myJournal"}
                  onClick={(event) => handleClick(event, "myJournal")}
                  onMouseEnter={() => setShowCategoryMenu(false)}>
                  <div
                    className={clsx({
                      [S.menuListInner]: true,
                      [S.active]: theme === "myJournal",
                    })}>
                    <div>
                      {intl.formatMessage({
                        id: "common.header.myJournal",
                        defaultMessage: "My Journal",
                      })}
                    </div>
                  </div>
                </a>
              ) : null}
              <PermittedTab
                userPrivilege={userPrivilege}
                handleClick={handleClick}
                theme={theme}
                onMouseEnter={() => setShowCategoryMenu(false)}
              />
            </div>
          </div>
          <div className={S.right}>
            <div className="language">
              <IntlButton />
            </div>
            <Notification />
            <LoginButton
              handleClick={handleClick}
              onClickLogin={() => {
                if (loginRef.current) {
                  loginRef.current.open();
                }
              }}
              setShowProfileMenu={() => setShowProfileMenu(!showProfileMenu)}
            />
          </div>
          {showProfileMenu ? (
            <ProfileMenu setShowProfileMenu={setShowProfileMenu} />
          ) : null}
          <CookieNotice />
        </div>
        <div className={display ? S.show : ""}>
          <LinearProgress />
        </div>
      </header>
    </Provider>
  );
};

export default Header;
