import AnalyticsAPI from "../analytics-api";

const loggingLoggedIn = (name = "Academy") =>
  AnalyticsAPI.track({
    verb: { id: "logged_in" },
    object: {
      name,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy",
      },
    },
    context: {
      extensions: {
        currentPage: window.location.href,
      },
    },
  });
const loggingPage = ({ action = "opened", page = "explore" }) =>
  AnalyticsAPI.track({
    verb: { id: action },
    object: {
      id: `pages/${page}`,
    },
  });

const loggingPreviewPage = ({
  action = "opened",
  title = "",
  itemId,
  targetType = "course",
}) =>
  AnalyticsAPI.track({
    verb: { id: action },
    object: {
      title,
      id: `pages/preview/${itemId}`,
      definition: {
        extensions: {
          targetType,
          [`${targetType}Id`]: itemId,
        },
      },
    },
  });

const loggingPreviewContent = ({
  actionType = "opened", // opened, closed, stayed
  courseId,
  itemType,
  itemId,
  title = "",
}) => {
  const itemIdKeyName = () => {
    switch (itemType) {
      case "video":
        return "videoId";
      case "document":
        return "documentId";
      case "assessment":
        return "assessmentId";
      case "h5":
        return "h5Id";
      default:
        return "videoId";
    }
  };
  const logData = {
    verb: { id: actionType },
    object: {
      name: title,
      id: `course/${courseId}/${itemType}/${itemId}`,
      definition: {
        type: `http://e4e.deltaww.com/deltaknew/academy/activities/${itemType}`,
        extensions: {
          type: "activity",
          subType: itemType,
          courseId,
          [itemIdKeyName()]: itemId,
        },
      },
    },
    interval: actionType === "stayed" ? 60 : undefined,
  };
  return AnalyticsAPI.track(logData);
};

const loggingAskQuestion = ({
  courseId,
  videoId,
  questionId,
  markerTime,
  playerView = "normal", // "normal"/"fullscreen"/"miniplayer"
}) =>
  AnalyticsAPI.track({
    verb: { id: "asked" },
    object: {
      id: `course/${courseId}/video/${videoId}/question/${questionId}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/discussion/question",
        extensions: {
          type: "discussion",
          subType: "question",
          courseId,
          videoId,
          questionId,
          markerTimepoint: markerTime,
          playerView,
        },
      },
    },
  });

const loggingQuestionLike = ({ likeStatus, courseId, videoId, questionId }) =>
  AnalyticsAPI.track({
    verb: { id: likeStatus ? "liked" : "unliked" },
    object: {
      id: `course/${courseId}/video/${videoId}/question/${questionId}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/discussion/question",
        extensions: {
          type: "discussion",
          subType: "question",
          courseId,
          videoId,
          questionId,
        },
      },
    },
  });

const loggingReply = ({ courseId, videoId, replyId }) =>
  AnalyticsAPI.track({
    verb: { id: "replied" },
    object: {
      id: `course/${courseId}/video/${videoId}/reply/${replyId}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/discussion/reply",
        extensions: {
          type: "discussion",
          subType: "reply",
          courseId,
          videoId,
          replyId,
        },
      },
    },
  });

const loggingReplyLike = ({
  likeStatus,
  courseId,
  videoId,
  questionId,
  replyId,
}) =>
  AnalyticsAPI.track({
    verb: { id: likeStatus ? "liked" : "unliked" },
    object: {
      id: `course/${courseId}/video/${videoId}/reply/${replyId}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/discussion/reply",
        extensions: {
          type: "discussion",
          subType: "reply",
          courseId,
          videoId,
          questionId,
          replyId,
        },
      },
    },
  });

const loggingReplyHelpful = ({
  helpful,
  courseId,
  videoId,
  replyId,
  questionId,
}) =>
  AnalyticsAPI.track({
    verb: { id: helpful ? "marked_helpful" : "unmarked_helpful" },
    object: {
      id: `course/${courseId}/video/${videoId}/reply/${replyId}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/discussion/reply",
        extensions: {
          type: "discussion",
          subType: "reply",
          courseId,
          videoId,
          questionId,
          replyId,
        },
      },
    },
  });

const loggingSectionMarkerClicked = ({ courseId, videoId, sectionId }) =>
  AnalyticsAPI.track({
    verb: { id: "clicked" },
    object: {
      id: `course/${courseId}/video/${videoId}/section_marker/${sectionId}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/marker/section_marker",
        extensions: {
          type: "marker",
          subType: "section_marker",
          courseId,
          videoId,
          sectionId,
        },
      },
    },
  });

const loggingImportantMarkerClicked = ({
  action = "opened",
  courseId,
  videoId,
  markerId,
  markerTimepoint,
}) =>
  AnalyticsAPI.track({
    verb: { id: action },
    object: {
      id: `course/${courseId}/video/${videoId}/important_marker/${markerId}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/marker/important_marker",
        extensions: {
          type: "marker",
          subType: "important_marker",
          courseId,
          videoId,
          markerId,
          markerTimepoint,
        },
      },
    },
  });

const loggingNoteClicked = ({
  action = "opened",
  courseId,
  videoId,
  markerId = [],
  markerTimepoint,
}) => {
  const queryString = markerId.join("&");
  return AnalyticsAPI.track({
    verb: { id: action },
    object: {
      id: `course/${courseId}/video/${videoId}/important_marker/${queryString}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/marker/important_marker",
        extensions: {
          type: "marker",
          subType: "note",
          courseId,
          videoId,
          markerId,
          markerTimepoint,
        },
      },
    },
  });
};

const loggingAddNote = ({
  courseId,
  videoId,
  noteId,
  markerTimepoint,
  source = "normal", // normal/fullscreen/miniplayer/note
}) =>
  AnalyticsAPI.track({
    verb: { id: "added" },
    object: {
      id: `course/${courseId}/video/${videoId}/note/${noteId}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/marker/note",
        extensions: {
          type: "marker",
          subType: "note",
          courseId,
          videoId,
          markerId: noteId,
          markerTimepoint,
          playerView: source,
        },
      },
    },
  });

const loggingEditNote = ({ courseId, videoId, noteId }) =>
  AnalyticsAPI.track({
    verb: { id: "edited" },
    object: {
      id: `course/${courseId}/video/${videoId}/note/${noteId}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/marker/note",
        extensions: {
          type: "marker",
          subType: "note",
          courseId,
          videoId,
          markerId: noteId,
        },
      },
    },
  });

const loggingRemoveNote = ({ courseId, videoId, noteId }) =>
  AnalyticsAPI.track({
    verb: { id: "deleted" },
    object: {
      id: `course/${courseId}/video/${videoId}/note/${noteId}`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/marker/note",
        extensions: {
          type: "marker",
          subType: "note",
          courseId,
          videoId,
          markerId: noteId,
        },
      },
    },
  });

const loggingRegistered = ({
  userType = "general_email", // general_email/social_google/social_facebook
}) =>
  AnalyticsAPI.track({
    verb: { id: "registered" },
    object: {
      id: "",
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy",
      },
    },
    context: {
      extensions: {
        userType,
      },
    },
  });

const loggingPurchased = ({
  itemType,
  itemId,
  orderType = "free",
  orderNumber = "",
}) =>
  AnalyticsAPI.track({
    verb: { id: "purchased" },
    object: {
      id: `order/${itemType === "course" ? "courseOrder" : "bundleOrder"}`,
      definition: {
        type: `http://e4e.deltaww.com/deltaknew/academy/order/${
          itemType === "course" ? "courseOrder" : "bundleOrder"
        }`,
        extensions: {
          type: "order",
          subType: itemType,
          [`${itemType}Id`]: itemId,
          orderType,
          orderNumber,
        },
      },
    },
  });

const loggingShareClicked = ({
  title,
  itemType, // course, bundle
  itemId,
}) =>
  AnalyticsAPI.track({
    verb: { id: "clicked" },
    object: {
      title,
      id: `pages/preview/${itemId}/share`,
      definition: {
        type: "http://e4e.deltaww.com/deltaknew/academy/component",
        extensions: {
          type: itemType,
          [`${itemType}Id`]: itemId,
        },
      },
    },
  });

const loggingShareCopy = ({
  itemType, // course, bundle
  itemId,
}) =>
  AnalyticsAPI.track({
    verb: {
      id: "shared_copy",
      extensions: {
        type: "shared",
        subType: "copy",
      },
    },
    object: {
      id: `${itemType}s/${itemId}`,
      definition: {
        type: `http://e4e.deltaww.com/deltaknew/academy/${itemType}`,
        extensions: {
          type: itemType,
          [`${itemType}Id`]: itemId,
        },
      },
    },
  });

const loggingShareEmail = ({
  itemType, // course, bundle
  itemId,
}) =>
  AnalyticsAPI.track({
    verb: {
      id: "shared_email",
      display: {
        "en-US": "emailed share link",
      },
      extensions: {
        type: "shared",
        subType: "email",
      },
    },
    object: {
      id: `${itemType}s/${itemId}`,
      definition: {
        type: `http://e4e.deltaww.com/deltaknew/academy/${itemType}`,
        extensions: {
          type: itemType,
          [`${itemType}Id`]: itemId,
        },
      },
    },
  });

export {
  loggingAddNote,
  loggingAskQuestion,
  loggingEditNote,
  loggingImportantMarkerClicked,
  loggingLoggedIn,
  loggingNoteClicked,
  loggingPage,
  loggingPreviewContent,
  loggingPreviewPage,
  loggingPurchased,
  loggingQuestionLike,
  loggingRegistered,
  loggingRemoveNote,
  loggingReply,
  loggingReplyHelpful,
  loggingReplyLike,
  loggingSectionMarkerClicked,
  loggingShareClicked,
  loggingShareCopy,
  loggingShareEmail,
};
