import { useAppContext } from "@components/context";
import { getUserProfile } from "@utils/apiServices/user";
import { isTokenAvailable } from "@utils/auth-utils";
import { useScrollPosition } from "@utils/hooks/use-scroll-position";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useHeaderData = ({ onSetDisplayHeader }) => {
  const router = useRouter();
  const { loginStatus } = useAppContext();
  const { token } = loginStatus || {};
  const [display, setDisplay] = useState(true);
  const [showCategoryMenu, setShowCategoryMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const checkScrollBarAndShowHeader = () => {
    if (
      (!display &&
        document.documentElement.clientHeight ===
          document.documentElement.scrollHeight) ||
      document.documentElement.scrollTop === 0
    ) {
      setDisplay(true);
      onSetDisplayHeader(true);
    }
  };

  const handleClick = (event, param) => {
    event.preventDefault();
    if (param === "") {
      router.push({ pathname: "/" }, `/${param}`);
    } else {
      router.push(`/${param}`);
    }
  };

  useEffect(() => {
    if (!isTokenAvailable(token)) {
      setShowProfileMenu(false);
    }
  }, [token]);

  useEffect(() => {
    if (showProfileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showProfileMenu]);

  useEffect(() => {
    checkScrollBarAndShowHeader();
  });

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (
        Math.abs(currPos.y) <
          document.documentElement.scrollHeight -
            document.documentElement.clientHeight &&
        currPos.y < 0
      ) {
        setDisplay(currPos.y > prevPos.y);
        onSetDisplayHeader(currPos.y > prevPos.y);
      }
    },
    [display],
  );

  return {
    display,
    setDisplay,
    showCategoryMenu,
    setShowCategoryMenu,
    handleClick,
    showProfileMenu,
    setShowProfileMenu,
  };
};

export const useProfileData = ({ token }) => {
  const { setLoginStatus } = useAppContext();
  const getProfileData = async () => {
    try {
      const { data: profile, error } = await getUserProfile();
      if (error) throw error;
      if (profile) {
        setLoginStatus((prev) => ({
          ...prev,
          ...profile,
          userLanguage: profile.meta.language,
        }));
      }
    } catch (error) {
      console.error("getProfileData", error);
    }
  };
  useEffect(() => {
    if (isTokenAvailable(token)) {
      getProfileData();
    }
  }, [token]);
};
