import LinearProgress from "@material-ui/core/LinearProgress";
import {
  addOnDownloadProgressFunc,
  addOnResponseFunc,
  addOnUploadProgressFunc,
} from "@utils/axios/client-api";
import { useRouter } from "next/router";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const ProgressBar = ({ forwardedRef }) => {
  const [showProgressBar, setShowProgressBar] = useState(false);

  useImperativeHandle(forwardedRef, () => ({
    setShowProgressBar,
  }));

  return (
    <LinearProgress
      style={{
        visibility: showProgressBar ? "visible" : "hidden",
        transform: "translateY(4px)",
        width: "100v",
        backgroundColor: "#e8e8e8",
        color: "#00adef",
      }}
    />
  );
};

const ForwardedProgressBar = forwardRef((props, ref) => (
  <ProgressBar forwardedRef={ref} {...props} />
));

ForwardedProgressBar.displayName = "ProgressBar";

export const useProgressBar = () => {
  const router = useRouter();
  const progressRef = useRef();

  const setShowProgressBar = (value) => {
    if (progressRef?.current) {
      progressRef.current.setShowProgressBar(value);
    }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      // close unService status
      setShowProgressBar(true);
    };
    const handleRouteChangeComplete = () => {
      setShowProgressBar(false);
    };
    const handleRouteChangeError = () => {
      setShowProgressBar(false);
    };
    router.events.on("routeChangeStart", handleRouteChange);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeError", handleRouteChangeError);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      router.events.off("routeChangeError", handleRouteChangeError);
    };
  }, []);

  useEffect(() => {
    addOnUploadProgressFunc(() => {
      setShowProgressBar(true);
    });
    addOnDownloadProgressFunc(() => {
      setShowProgressBar(true);
    });
    addOnResponseFunc(() => {
      setShowProgressBar(false);
    });
  }, []);

  return {
    setShowProgressBar,
    LinearProgress: () => <ForwardedProgressBar ref={progressRef} />,
  };
};
