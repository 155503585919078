import { useEffect, useRef, useState } from "react";

const isBrowser = typeof window !== "undefined";

export const getScrollPosition = ({ element, useWindow }) => {
  if (!isBrowser) {
    return { x: 0, y: 0 };
  }

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
};

export const useScrollPosition = (effect, deps, element, useWindow, wait) => {
  const position = useRef(getScrollPosition({ useWindow }));
  const [throttleTimeout, setThrottleTimeout] = useState(null);

  useEffect(() => {
    const scrollCallBack = () => {
      const currPos = getScrollPosition({ element, useWindow });
      effect({ prevPos: position.current, currPos });
      position.current = currPos;
      setThrottleTimeout(null);
    };

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          setThrottleTimeout(setTimeout(scrollCallBack, wait));
        }
      } else {
        scrollCallBack();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [effect, element, throttleTimeout, useWindow, wait]);
};
