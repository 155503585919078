import { cookieConfig } from "@configs/cookieConfig";
import { setSentryUser } from "@utils/sentry-utils";
import cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import empty from "ramda/es/empty";

const encodeValidStringData = (data) =>
  typeof data === "string" ? encodeURIComponent(data) : "";

export const setAuthCookie = ({ avatar, email, name, token }) => {
  setSentryUser({
    loginStatus: {
      email,
      name,
      token,
    },
    id: cookies.get("academy-session"),
  });
  cookies.set("email", email, cookieConfig);
  cookies.set("avatar", encodeURIComponent(avatar), cookieConfig);
  cookies.remove("name", cookieConfig);
  cookies.set("name", encodeValidStringData(name), cookieConfig);
  cookies.set("token", token, cookieConfig);
  console.log("[CookieSet] name", encodeValidStringData(name));
  console.log("[CookieSet] email", email);
};

export const isTokenAvailable = (token) => {
  try {
    if (
      !empty(token) &&
      token !== "" &&
      typeof token !== "undefined" &&
      token !== "undefined"
    ) {
      // Able to decode
      if (jwtDecode(token)) {
        return true;
      }
    }
  } catch (error) {
    return false;
  }
  return false;
};

export const checkAndParseToken = (token) => {
  try {
    if (isTokenAvailable(token)) {
      return {
        ...jwtDecode(token),
      };
    }
  } catch (error) {
    console.error("checkAndParseToken", error);
  }
  return {};
};
