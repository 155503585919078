import { displayUnauthorized } from "@components/AppEnv";
import { useAppContext } from "@components/context";
import { Button } from "@material-ui/core";
import S from "@styles/components/_button.module.scss";
import { isTokenAvailable } from "@utils/auth-utils";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { useIntl } from "react-intl";

const Avatar = dynamic(() => import("@components/Avatar"), {
  ssr: false,
});

const noLoginActionPathList = ["/login/invite", "/login/forgetPassword"];

const LoginButton = ({ handleClick, onClickLogin, setShowProfileMenu }) => {
  const intl = useIntl();
  const { loginStatus } = useAppContext();
  const router = useRouter();
  const { pathname } = router;
  const { avatar = null, email, token, name } = loginStatus || {};
  const noLoginAction = noLoginActionPathList.indexOf(pathname) !== -1;

  const onClick = (event) => {
    if (displayUnauthorized) {
      onClickLogin(event);
    } else {
      handleClick(event, "login");
    }
  };

  if (noLoginAction) return null;

  return isTokenAvailable(token) ? (
    <div id="avatarButton" role="button" tabIndex={-1} title="avatarButton">
      <Avatar
        name={name}
        key={email}
        width={40}
        src={avatar}
        onClick={setShowProfileMenu}
        isPointer={true}
      />
    </div>
  ) : (
    <Fragment>
      <Button id="headerLogin" className={S.btnLogin} onClick={onClick}>
        {intl.formatMessage({
          id: "login.button.login",
          defaultMessage: "Login",
        })}
      </Button>
    </Fragment>
  );
};

export default LoginButton;
