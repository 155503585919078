import clsx from "clsx";
import { includes } from "ramda";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import S from "./Header.module.scss";

const PermittedTab = ({
  theme,
  userPrivilege = [],
  handleClick,
  onMouseEnter,
}) => {
  const intl = useIntl();
  const privilegeKeys = useMemo(() => userPrivilege || [], [userPrivilege]);
  const accessStudio = useMemo(
    () => includes("ac-studio-1", privilegeKeys),
    [privilegeKeys],
  );
  const accessConsole = useMemo(
    () => includes("ac-console-1", privilegeKeys),
    [privilegeKeys],
  );
  return (
    <>
      {accessStudio ? (
        <a
          test-id="studio"
          key="ac-studio-1"
          href={"/studio/courseware/myCourse"}
          onClick={(event) => handleClick(event, "studio/courseware/myCourse")}>
          <div
            className={clsx({
              [S.menuListInner]: true,
              [S.active]: theme === "studio",
            })}
            onMouseEnter={onMouseEnter}>
            <div>
              {intl.formatMessage({
                id: "common.header.studio",
                description: "Studio tab in Header",
                defaultMessage: "Studio",
              })}
            </div>
          </div>
        </a>
      ) : null}
      {accessConsole ? (
        <a
          test-id="console"
          key="ac-console-1"
          href={"/console"}
          onClick={(event) => handleClick(event, "console")}
          onMouseEnter={onMouseEnter}>
          <div
            className={clsx({
              [S.menuListInner]: true,
              [S.active]: theme === "console",
            })}>
            <div>
              {intl.formatMessage({
                id: "common.header.console",
                description: "Console tab in Header",
                defaultMessage: "Console",
              })}
            </div>
          </div>
        </a>
      ) : null}
    </>
  );
};

export default PermittedTab;
