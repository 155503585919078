import dynamic from "next/dynamic";
import React, { forwardRef } from "react";

const Notification = dynamic(() => import("./Notification"), { ssr: false });

const NotificationContainer = forwardRef((props, ref) => (
  <Notification {...props} forwardedRef={ref} />
));

NotificationContainer.displayName = "Notification";

export default NotificationContainer;
