import { appJson, REACT_APP_ENV } from "@components/AppEnv";
import clientAPI from "@utils/axios/client-api";
import cookies from "js-cookie";
import short from "short-uuid";

/**
 * Analytics API manager
 *
 * - Singleton Pattern
 * - track() method
 * - Send action object: ANALYTICS_TRACK_EVENT_[REQUEST, SUCCESS, FAILED], listening them in Custom Reducer to do more
 * - ...
 */

const convertLoggingVerb = ({ id, ...otherProps }) => ({
  id: `http://e4e.deltaww.com/deltaknew/academy/verbs/${id}`,
  display: {
    "en-US": id.replace("_", " "),
  },
  ...otherProps,
});

const convertLoggingObject = ({ id, definition = {}, ...rest }) => {
  const idParam = id ? `/${id}` : "";
  return {
    definition: {
      type: "http://e4e.deltaww.com/deltaknew/academy/activities/page",
      ...definition,
    },
    ...rest,
    id: `http://e4e.deltaww.com/deltaknew/academy${idParam}`,
  };
};

export class Analytics {
  static instance;

  static store;

  static axiosInstance;

  static sessionId;

  static userPrivacy = {};

  static track = (params) => {
    const {
      verb = { id: "" },
      object = "",
      context = {},
      interval,
    } = params || {};
    const { activity, enrollment, location, information } =
      Analytics.userPrivacy;
    if (!activity) return;
    if (!enrollment && object.id?.includes("purchased")) return;
    console.log("[logging]", Analytics.sessionId);
    const tempParams = {
      verb: convertLoggingVerb(verb),
      object: convertLoggingObject(object),
      context: {
        platform: "web",
        extensions: {
          device: information
            ? {
                userAgent: window.navigator.userAgent,
                location: location || null,
                language: window.navigator.language,
                resolution: {
                  width: window.screen.width,
                  height: window.screen.height,
                },
                browserSize: {
                  width: window.outerWidth,
                  height: window.outerHeight,
                },
              }
            : {},
          // TODO: device send following information
          // - browser user agent
          // - location
          // - language
          // - OS
          // - Computer screen resolution
          // - Browser size
          session: Analytics.sessionId,
          academyVersion: appJson.version[REACT_APP_ENV],
          ...context.extensions,
          interval,
        },
      },
      timestamp: new Date().toISOString(),
    };
    clientAPI
      .post("/api/v1/logging", tempParams)
      .then((response) => response.data)
      .catch((error) => {
        console.error("logging failed", error);
      });
  };

  static refreshSession() {
    Analytics.sessionId = short.uuid();
  }

  static getInstance() {
    if (!Analytics.instance) {
      const cookieSession = cookies.get("academy-session");
      if (cookieSession) {
        Analytics.sessionId = cookieSession;
      } else {
        Analytics.sessionId = short.uuid();
      }
      Analytics.instance = {
        track: Analytics.track,
      };
    }

    return Analytics.instance;
  }

  static configure(store, axiosInstance) {
    Analytics.store = store;
    Analytics.axiosInstance = axiosInstance;
  }

  static setAnalyticsAPIUserPrivacy = (userPrivacy) => {
    Analytics.userPrivacy = userPrivacy;
  };
}

const { configure: configureAnalyticsAPI, setAnalyticsAPIUserPrivacy } =
  Analytics;
export { configureAnalyticsAPI, setAnalyticsAPIUserPrivacy };

const AnalyticsAPI = Analytics.getInstance();
export default AnalyticsAPI;
